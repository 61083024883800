<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                                ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <div style="border-bottom: inset;">
        <form class="ml-1 mr-1">
            <v-container style="display: flex">
                <v-text-field v-model="filtros.codigoCohan" style="width:35%; margin-right: 10px;" dense outlined
                    label="Buscar por código cohan"></v-text-field>
                <v-text-field v-model="filtros.codigoGenerico" style="width:35%; margin-right: 5px;" dense outlined
                    label="Buscar por código genérico"></v-text-field>
                <v-tooltip left color="success">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="abrirDialogHomologacion()" small class="ms-2" fab color="success" v-bind="attrs" v-on="on" >
                        <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Homologar un producto</span>
                </v-tooltip>
            </v-container>
        </form>
        <v-data-table :headers="encabezadoTablaPrincipal" :items="listaPrincipal" :footer-props="footerProps"
            :items-per-page="itemsPorPagina" :page="pagina" :server-items-length="totalElementos"
            @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p"
            class="elevation-1 tablaPrincipal" fixed-header style="margin: 0px 14px 14px 14px" height="58vh"
            loading-text="Cargando...">
            <template v-slot:[`item.editar`]="{ item }">
                <v-btn @click="mostrarInformacionActual(item)" icon>
                    <v-icon color="orange"> border_color </v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.activo`]="{ item }">
                <v-btn @click="abrirDialogActivacion(item)" icon>
                    <v-icon :color="item.eliminado === true ? 'error' : 'success'"> check_circle </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog persistent v-model="dialogHomologacion" max-width="50%">
            <form fast-fail @submit.prevent>
                <v-card>
                    <v-card-title style="background-color: #0D47A1; color: white">Formulario homologación</v-card-title>
                    <v-container class="inputs">
                        <v-autocomplete @keyup="delayCodigosStone($event.target.value)" v-model="codigoAHomologar"
                            :items="listadoCodigosStone" dense outlined label="Código producto"
                            class="mr-2 ml-2 mt-2 mb-2"></v-autocomplete>
                        <v-autocomplete :items="opcionesPrioridad" :disabled="desactivarInputPrioridad"
                            :filled="desactivarInputPrioridad" v-model="numeroPrioridad" dense outlined label="N° prioridad"
                            class="mr-2 ml-2 mb-2"></v-autocomplete>
                        <v-autocomplete :items="opcionesArticulo" :disabled="desactivarInputArticulo"
                            :filled="desactivarInputArticulo" v-model="tipoArticulo" dense outlined label="Tipo artículo"
                            class="mr-2 ml-2 mb-2"></v-autocomplete>
                        <v-autocomplete @keyup="delayCodigosGenericos($event.target.value)" return-object
                            :items="respuestaCodigosGenericos" :item-text="mostrarCodigosGenericos"
                            :disabled="desactivarInputGenerico" :filled="desactivarInputGenerico" v-model="codigoGenerico"
                            dense outlined label="Código genérico ~ Código cliente"
                            class="mr-2 ml-2 mb-2"></v-autocomplete>
                        <v-text-field type="text" v-model="nuevoCodigoGenerico" v-if="mostarInputGenerico"
                            label="Ingresar nuevo código genérico" class="mr-2 ml-2 mb-2" dense outlined></v-text-field>
                        <v-text-field type="text" v-model="nuevoCodigoCliente" v-if="mostrarInputCliente"
                            label="Ingresar nuevo código cliente" class="mr-2 ml-2 mb-2" dense outlined></v-text-field>
                        <v-combobox @keyup="setNuevaDescripcion($event.target.value)" v-model="nuevaDescripcion"
                            :items="listaCodigosGenericos" return-object :item-text="seleccionDescripcion" v-if="mostrarInputDescripcion"
                            label="Ingresar la descripción del producto" class="mr-2 ml-2 mb-2" dense
                            outlined :disabled="desactivarDescripcion" :filled="desactivarDescripcion"></v-combobox>
                    </v-container>
                    <v-container style="display: flex; justify-content: end; padding-top: 0;" class="pr-5">
                        <v-btn @click="cerrarDialogHomologacion()" text style="max-width: 20%;" class="mr-4"
                            color="error">cerrar</v-btn>
                        <v-btn @click="postAgregarNuevaFila()" color="success"
                            style="color:aliceblue; max-width:20%;">guardar</v-btn>
                    </v-container>
                </v-card>
            </form>
        </v-dialog>
        <v-dialog v-model="dialogEditar" max-width="50%">
            <form>
                <v-card>
                    <v-card-title style="background-color: #0D47A1; color: white"> Editar la información</v-card-title>
                    <v-container>
                        <v-text-field disabled filled v-model="editCodigoCohan"
                            :items="listadoCodigosStone" dense outlined label="Código producto"
                            class="mr-2 ml-2 mt-2 mb-2"></v-text-field>
                        <v-autocomplete :items="opcionesPrioridad" v-model="editPrioridad" dense outlined label="N° prioridad"
                            class="mr-2 ml-2 mb-2"></v-autocomplete>
                        <v-autocomplete :items="opcionesArticulo" v-model="editArticulo" dense outlined label="Tipo artículo"
                            class="mr-2 ml-2 mb-2"></v-autocomplete>
                        <v-text-field v-model="editCodigoGenerico" label="Código genérico"
                            class="mr-2 ml-2 mb-2" dense outlined></v-text-field>
                        <v-text-field v-model="editCodigoCliente" label="Código cliente"
                            class="mr-2 ml-2 mb-2" dense outlined></v-text-field>
                         <v-combobox @keyup="setEditDescripcion($event.target.value)" v-model="editDescripcion" :items="listaCodigosGenericos" return-object
                            :item-text="seleccionDescripcion"
                            label="Descripción del producto" class="mr-2 ml-2 mb-2" dense
                            outlined></v-combobox>
                    </v-container>
                    <v-container style="display: flex; justify-content: end; padding-top: 0;" class="pr-5">
                        <v-btn @click="cerrarDialogEditar()" style="max-width: 20%;" class="mr-4" text
                            color="error">cerrar</v-btn>
                        <v-btn @click="editarInformacion()" color="green" style="color:aliceblue; max-width:20%;"
                         :disabled="desactivarBotonGuardar" :filled="desactivarBotonGuardar" >Guardar</v-btn>
                    </v-container>
                </v-card>
            </form>
        </v-dialog>
        <v-dialog persistent max-width="35%" v-model="dialogActivacion">
            <form>
                <v-card>
                <v-card-title style="background-color: #005296; color: white">{{ tituloDialogActivar }}</v-card-title>
                    <div class="d-flex justify-end pa-3 me-1">
                        <v-btn class="me-3" color="error" text @click="dialogActivacion = false"> No </v-btn>
                        <v-btn color="success" @click="editarEstado()"> Si </v-btn>
                    </div>
                </v-card>
            </form>
        </v-dialog>
    </div>
</template>

<script>

import { mapMutations } from "vuex";

const debounce = function debounce(fn, delay) {
    var timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

export default {
    data() {
        return {
            encabezadoTablaPrincipal: [
                { text: "CÓDIGO COHAN", align: "center", value: "codigo_producto", sortable: false },
                { text: "CÓDIGO GENERICO", align: "center", value: "producto_generico", sortable: false },
                { text: "CÓDIGO CLIENTE", align: "center", value: "producto_cliente", sortable: false },
                { text: "TIPO ARTÍCULO", align: "center", value: "tipo_articulo", sortable: false },
                { text: "DESCRIPCIÓN", align: "center", value: "descripcion_producto", sortable: false },
                { text: "EDITAR", align: "center", value: "editar", sortable: false },
                { text: "ACTIVO", align: "center", value: "activo", sortable: false },
            ],
            opcionesPrioridad: [
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
                { text: "4", value: 4 },
                { text: "5", value: 5 },
                { text: "6", value: 6 },
                { text: "7", value: 7 }
            ],
            opcionesArticulo: [
                { text: "PROPIO", value: "P" },
                { text: "CUSTODIA", value: "C" },
            ],
            footerProps: {
                showFirstLastPage: true,
                "show-current-page": true,
                'items-per-page-text': 'Ítems por página',
                'items-per-page-options': [5, 10, 20, 50,],
                'items-length': 100
            },
            filtros: {
                codigoCohan: "",
                codigoGenerico: "",
            },
            pagina: 1,
            itemsPorPagina: 10,
            totalElementos: 10,
            dialogHomologacion: false,
            dialogEditar: false,
            dialogActivacion: false,
            tituloDialogActivar: "",
            informacionFilaPrincipal: {},
            codigoAHomologar: "",
            numeroPrioridad: "",
            tipoArticulo: "",
            nuevoCodigoGenerico: "",
            nuevoCodigoCliente: "",
            nuevaDescripcion: "",
            codigoGenerico: {},
            listadoCodigosStone: [],
            listaPrincipal: [],
            respuestaValidacion: [],
            respuestaCodigosGenericos: [],
            listaCodigosGenericos:[],
            respuestaValidarCodigos:null,
            colorBotonActivo: "",
            desactivarBotonGuardar: false,
            desactivarInputPrioridad: true,
            desactivarInputArticulo: true,
            desactivarInputGenerico: true,
            desactivarDescripcion: false,
            mostarInputGenerico: false,
            mostrarInputCliente: false,
            mostrarInputDescripcion: false,
            editCodigoCohan: "",
            editPrioridad: "",
            editArticulo: "",
            editCodigoGenerico: "",
            editCodigoCliente: "",
            editDescripcion: "",
            codigoCohanElejido: "",
        }
    },

    created() {
        this.getListaPrincipal()
    },

    methods: {

        ...mapMutations([
        "showSuccess",
        "showError"
        ]),

        setEditDescripcion(param){
            this.editDescripcion = param;
        },

        setNuevaDescripcion(param){
            this.nuevaDescripcion = param;
        },

        delayCodigosStone: debounce(function (param) {
            this.getCodigosStone(param);
        }, 500),

        getCodigosStone(codigoGenerico) {
            this.$http.get("/msa-administration/caDecli/listaCodigosProductosStone", {
                params: {
                    codigo: codigoGenerico,
                }
            }).then((result) => {
                this.listadoCodigosStone = result.data;
            }).catch((error) => {
                console.log(error)
            })
        },

        seleccionDescripcion(item){
            return item.descripcionProducto;
        },

        getListaPrincipal() {
            this.$http.get("/msa-administration/HomologacionGomedisys/listaPrincipal", {
                params: {
                    page: this.pagina - 1,
                    size: this.itemsPorPagina,
                    codigoCohan: this.filtros.codigoCohan,
                    codigoGenerico: this.filtros.codigoGenerico
                }
            }).then((result) => {
                this.listaPrincipal = result.data.content
                this.totalElementos = result.data.totalElements
            })
        },

        getValidarCodigoExistente(codigo) {
            this.$http.get("/msa-administration/HomologacionGomedisys/validarCodigoExistente", {
                params: {
                    codigoProducto: codigo,
                }
            }).then((result) => {
                this.respuestaValidacion = result?.data
                if(this.respuestaValidacion != null && this.respuestaValidacion?.eliminado == false) {
                    this.showSuccess("El código seleccionado ya ha sido homologado");
                    this.desactivarInputPrioridad = true;
                    this.desactivarBotonGuardar = true;
                }
                if(this.respuestaValidacion != null && this.respuestaValidacion?.eliminado == true){
                    this.showSuccess("El código seleccionado ya ha sido homologado, si desea usarlo debe activarlo primero.");
                    this.desactivarInputPrioridad = true;
                    this.desactivarBotonGuardar = true;
                }
                if(this.respuestaValidacion == null){
                    this.desactivarInputPrioridad = false;
                    this.desactivarBotonGuardar = false;
                }
                if(this.codigoAHomologar == null) {
                    this.desactivarInputPrioridad = true;
                }
            })
        },

        delayCodigosGenericos: debounce(function (param) {
            this.getListarCodigosGenericos(param);
        }, 500),

        getListarCodigosGenericos(codigoGenerico) {
            return new Promise((resolve) => {
                this.$http.get("/msa-administration/HomologacionGomedisys/listarCodigosGenericos", {
                params: {
                    codigoGenerico: codigoGenerico
                }
            }).then((result) => {
                let resultado = result.data;
                let anexo = [{
                    "productoGenerico": "El CODIGO NO ESTA EN LA LISTA",
                    "productoCliente": "",
                    "descripcionProducto": ""
                }]
                this.respuestaCodigosGenericos = anexo.concat(resultado)
                resolve(true)
            })
            })
        },
        
        getBuscarCodigoGenerico(codigoGenerico) {
            return new Promise((resolve) => {
                this.$http.get("/msa-administration/HomologacionGomedisys/buscarCodigoGenerico", {
                params: {
                    codigoGenerico: codigoGenerico
                }
            }).then((result) => {
                this.listaCodigosGenericos = result.data;
                 if(this.listaCodigosGenericos?.length != 0){
                    this.editDescripcion = this.listaCodigosGenericos[0]?.descripcionProducto; 
                    this.nuevaDescripcion = this.listaCodigosGenericos[0]?.descripcionProducto; 
                    this.desactivarDescripcion = true;
                 }else{
                    this.editDescripcion = "";
                    this.nuevaDescripcion = "";
                    this.desactivarDescripcion = false;
                 }
                resolve(true)
            })
            })
        },

        async postAgregarNuevaFila() {
            if(this.mostarInputGenerico == false) {
                if(this.codigoAHomologar != null && this.numeroPrioridad != ""
                    && this.tipoArticulo != "" && this.codigoGenerico?.productoGenerico != null) {
                    this.$http.post("/msa-administration/HomologacionGomedisys/agregarHomologacion", {
                        codigoProducto: this.codigoAHomologar,
                        codigoPrioridad: this.numeroPrioridad,
                        tipoArticulo: this.tipoArticulo,
                        listaPrecio: 7221,
                        productoGenerico: this.codigoGenerico?.productoGenerico,
                        productoCliente: this.codigoGenerico?.productoCliente,
                        descripcionProducto: this.codigoGenerico?.descripcionProducto
                    }).then(() => {
                        this.cerrarDialogHomologacion();
                        this.showSuccess("El código se ha homologado con éxito ");
                    })
                }else{
                    this.showError("Debe llenar todos los campos");
                }
            }
            if(this.mostarInputGenerico == true) {
                await this.validarCodigoGenerico(this.nuevoCodigoGenerico.toUpperCase(), this.nuevoCodigoCliente)
                let regex = /^[a-zA-Z0-9]*$/;
                let regexCliente = /^[0-9]*$/;
                if(this.codigoAHomologar != null && this.numeroPrioridad != ""
                    && this.tipoArticulo != "" && this.nuevoCodigoGenerico != ""
                    && this.nuevoCodigoCliente != "" && this.nuevaDescripcion != "") {
                    if(regex.test(this.nuevoCodigoGenerico)){
                        if(regexCliente.test(this.nuevoCodigoCliente)){
                            if(this.respuestaValidarCodigos == true){
                                this.$http.post("/msa-administration/HomologacionGomedisys/agregarHomologacion", {
                                    codigoProducto: this.codigoAHomologar,
                                    codigoPrioridad: this.numeroPrioridad,
                                    tipoArticulo: this.tipoArticulo,
                                    listaPrecio: 7221,
                                    productoGenerico: this.nuevoCodigoGenerico.toUpperCase(),
                                    productoCliente: this.nuevoCodigoCliente,
                                    descripcionProducto: this.nuevaDescripcion.toUpperCase()
                                }).then(() => {
                                    this.cerrarDialogHomologacion()
                                    this.showSuccess("El código se ha homologado con éxito");
                                }).catch((e) => {
                                    this.showError("Ocurrio un error inesperado, por favor contacte con un administrador");
                                    console.log(e);
                                })
                            }else{
                                this.showSuccess("El código genérico y código cliente ingresados ya existen");
                            }
                        }else{
                            this.showError("El código cliente solo puede contener números mayores o iguales a 0");
                        }
                    }else{
                        this.showError("El campo código genérico no puede tener caracteres especiales");
                    }
                }else{
                    this.showError("Debe llenar todos los campos");
                }
            }
        },

        abrirDialogActivacion(item){
            this.informacionFilaPrincipal = item;
            this.tituloDialogActivar =  this.informacionFilaPrincipal?.eliminado === true ? "¿Desea activar el codigo?" : "¿Desea inactivar el codigo?";
            this.dialogActivacion = true;
        },

        editarEstado() {
            this.$http.post("/msa-administration/HomologacionGomedisys/activarCodigo", this.informacionFilaPrincipal.id).then(() => {
                this.getListaPrincipal()
            }).then(() => {
                this.dialogActivacion = false;
                this.showSuccess("El estado ha sido editado correctamente")
            }).catch(() => {
                this.dialogActivacion = false;
                this.showError("Error editando el estado por favor contacte con un administrador")
            })
        },

        editarInformacion(){
            if(this.editPrioridad != null && this.editArticulo
             != null && this.editCodigoGenerico != "" && this.editCodigoCliente
             != "" && this.editDescripcion != ""){
                let regex = /^[a-zA-Z0-9]*$/;
                let regexCliente = /^[0-9]*$/;
                if(regex.test(this.editCodigoGenerico)){
                    if(regexCliente.test((this.editCodigoCliente))){
                        this.$http.put("/msa-administration/HomologacionGomedisys/editarInformacion", {
                            codigoProducto: this.editCodigoCohan,
                            codigoPrioridad: this.editPrioridad,
                            tipoArticulo: this.editArticulo,
                            productoGenerico: this.editCodigoGenerico.toUpperCase(),
                            productoCliente: this.editCodigoCliente,
                            descripcionProducto: this.editDescripcion.toUpperCase(),
                        }).then((result) => {
                            if(result.status == 200) {
                                this.getListaPrincipal();
                                this.dialogEditar = false;
                                this.showSuccess("La información del producto ha sido editada correctamente")
                            }
                        }).catch(() => {
                            this.showError("Error editando el estado por favor contacte con un administrador")
                        })
                    }else{
                        this.showError("El código cliente solo puede contener números mayores o iguales a 0");     
                    }
                }else{
                    this.showError("El campo código genérico no puede tener caracteres especiales");
                }
            }else{
                this.showError("Debe llenar todos los campos");
            }
        },

        validarCodigoGenerico(productoGenerico, productoCliente){
            return new Promise((resolve) => {
                this.$http.get("/msa-administration/HomologacionGomedisys/validarCodigos", {
                    params: {
                        productoGenerico: productoGenerico,
                        productoCliente: productoCliente
                    }
                }).then((result) => {
                    this.respuestaValidarCodigos = result.data;
                    resolve(true)
                }).catch((error) => {
                    console.log(error)
                    resolve(false)
                })
            })
        },

        mostrarCodigosGenericos(item) {
            return item.productoGenerico + "-" + item.productoCliente;
        },

        async mostrarInformacionActual(item) {
            this.dialogEditar = true;
            this.listadoCodigosStone.push(item.codigo_producto);
            this.editCodigoCohan = item.codigo_producto;
            this.codigoCohanElejido = item.codigo_producto;
            this.editPrioridad = item.codigo_prioridad;
            this.editCodigoGenerico = item.producto_generico;
            this.editCodigoCliente = item.producto_cliente;
            await this.getBuscarCodigoGenerico(this.editCodigoGenerico)
            if(item.tipo_articulo == "PROPIO"){
                this.editArticulo = "P";
            }else{
                this.editArticulo = "C";
            }

        },

        abrirDialogHomologacion() {
            this.desactivarInputPrioridad = true;
            this.desactivarInputArticulo = true;
            this.desactivarInputGenerico = true;
            this.dialogHomologacion = true;
        },

        cerrarDialogHomologacion() {
            this.dialogHomologacion = false;
            this.mostarInputGenerico = false;
            this.mostrarInputCliente = false;
            this.mostrarInputDescripcion = false;
            this.codigoAHomologar = "";
            this.tipoArticulo = "";
            this.numeroPrioridad = "";
            this.nuevoCodigoGenerico = "";
            this.nuevoCodigoCliente = "";
            this.nuevaDescripcion = "";
            this.codigoGenerico = {};
        },

        cerrarDialogEditar(){
            this.dialogEditar = false;
            this.editDescripcion = "";
            this.nuevaDescripcion = "";
        }
    },

    watch: {

        "itemsPorPagina": function () {
            this.getListaPrincipal();
        },

        "pagina": function () {
            this.getListaPrincipal();
        },

        "codigoAHomologar": function () {
            this.getValidarCodigoExistente(this.codigoAHomologar);
        },

        "numeroPrioridad": function () {
            this.desactivarInputArticulo = false;
            if(this.numeroPrioridad == null) {
                this.desactivarInputArticulo = true;
            }
        },

        "tipoArticulo": function () {
            this.desactivarInputGenerico = false;
            if(this.tipoArticulo == null) {
                this.desactivarInputGenerico = true;
            }
            this.getListarCodigosGenericos();
        },

        "editCodigoGenerico": debounce(function () {
            this.getBuscarCodigoGenerico(this.editCodigoGenerico);
        }, 500),

        "nuevoCodigoGenerico": debounce(function () {
            this.getBuscarCodigoGenerico(this.nuevoCodigoGenerico);
        }, 500),

        "filtros.codigoCohan": debounce(function () {
            this.getListaPrincipal();
        }, 500),

        "filtros.codigoGenerico": debounce(function () {
            this.getListaPrincipal();
        }, 500),

        "codigoGenerico.productoGenerico": function () {
            if(this.codigoGenerico?.productoGenerico == "El CODIGO NO ESTA EN LA LISTA") {
                this.mostarInputGenerico = true;
                this.mostrarInputCliente = true;
                this.mostrarInputDescripcion = true;
            }else{
                this.mostarInputGenerico = false;
                this.mostrarInputCliente = false;
                this.mostrarInputDescripcion = false;
            }
        }
    },

}

</script>

<style scoped>

::v-deep .v-messages {
    display: none;
}

::v-deep .v-text-field__details {
    display: none;
}

::v-deep .tablaPrincipal div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaPrincipal div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

</style>